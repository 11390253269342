.blockquote{
    width:100%;

    blockquote{
        display: block;
        margin:auto;
        padding:30px 0px;
        font-size:32px;
        font-family: $perpetua;
        color: $blue;
        width:90%;
        max-width:800px;
        border-top:1px solid $gray-transparent;
        border-bottom:1px solid $gray-transparent;
    }
}