.image_box{

    margin:auto;
    padding: 40px 20px 0px;

    @media screen and (max-width:$mobile){
        padding:40px 0px 0px;
    }

    &__content{
        position:relative;
        margin-top:30px;
        padding:5% 0px 0px;
        @include clear();

        &__img{
            position:absolute;
            left:0px;
            top:0px;
            width:100%;
            height:100%;
            background-size:cover;
            background-position: center;

            @media screen and (max-width:$content-break){
                position:relative;
                height:auto;
                padding-bottom:40%;
            }
        }

        &__copy{
            width:40%;
            margin-right:5%;
            position:relative;
            float:right;
            background-color: $white;
            padding:10px 30px 30px 10%;

            @media screen and (max-width:$content-break){
                position:relative;
                height:auto;
                padding-bottom:30%;
                float:none;
                width:100%;
            }

            p{
                font-family: $perpetua;
                font-size:22px;
            }

            h3{
                &:first-of-type{
                    display: block;
                    position:relative;
                    color: $blue;
                    text-transform: uppercase;
                    font-family: $helvetica-bold;
                    font-size:14px;

                    @media screen and (max-width:$content-break){
                        margin-top: 60px;
                    }

                    &:before{
                        content:'';
                        position:absolute;
                        top:-10px;
                        left:-65px;
                        width:50px;
                        height: 40px;
                        background-image:url('/wp-content/themes/hudson_advisors/assets/img/logo_icon.png');
                        background-size:contain;

                        @media screen and (max-width:$content-break){
                            left:0px;
                            top:-60px;
                        }
                    }
                }
            }
        }
    }
}