body.home {
   .bg-img {
      background-repeat:no-repeat;
      background-size:cover;
   }

   .hero {
      background-color: #000;
      height: 624px;
      padding: 0;
      position: relative;

      @include for-size(tablet) {
         height: 430px;
      }

      .copy {
         color: $white;
         font-family: $perpetua;
         font-size: 70px;
         left: 50%; 
         line-height: 1;
         margin: 0 auto;
         max-width: 900px;
         padding: 0 30px;
         position: absolute;
         text-align: center;
         top: 50%;  
         transform: translate(-50%, -50%);
         width: 100%;

         @include for-size(tablet) {
            font-size: 40px;
            line-height: 1;
         }
      }

      video {
         height: 624px;
         object-fit: cover;
         width: 100vw;

         @include for-size(tablet) {
            height: 430px;
         }
      }
   }

   section {
      .section-title {
         font-size: 40px;
         line-height: 60px;
         padding: 40px 0 60px 0;
         text-align: center;

         a {
            color: $black;
         }
      }

      ul {
         display: flex;
         gap: 20px;
         justify-content: space-between;
         list-style-type: none;
         margin: 0;
         padding: 0;

         @include for-size(tablet) {
            align-items: center;
            flex-direction: column;
            gap: 50px;
         }

         li {
            width: 430px;
         }
      }

   }
   
   .numbers-section {
      margin: 0 auto;
      max-width: 1440px;
      width: 100%;

      ul.numbers {
         li.number {
            text-align: center;
            
            .title {
               color: #89AEBE;
               font-family: $perpetua;
               font-size: 120px;
               line-height: 70px;
               margin-bottom: 30px;
               display: flex;
               justify-content: center;
               align-items: center;
               
               @include for-size(tablet) {
                  margin-bottom: 15px;
               }

               .before {

               }

               .number {

               }

               .after {

               }
               
               @include for-size(phone) {
                  font-size: 80px;
                  margin-bottom: 10px;
               }
            }
            
            .subtitle {
               color: #707070;
               font-size: 16px;
               font-weight: bold;
            }
         }
      }
   }

   .platform-section {
      background-color: rgba(216, 216, 216, 0.18);
      margin-top: 90px;
      padding-bottom: 100px;
      width: 100%;
      
      .content {
         margin: 0 auto;
         max-width: 1440px;

         li {
            align-items: center;
            display: flex;
            height: 340px;
            justify-content: center;
            position: relative;
            
            &:hover {
               .content {
                  justify-content: space-between;

                  .subtitle,
                  .cta {
                     display: block;
                  }
               }
               .cover {
                  background-color: #3C6960;
                  opacity: 1;
               }
            }
            
            .content {
               color: $white;
               display: flex;
               flex-direction: column;
               justify-content: center;
               height: 100%;
               padding: 45px;
               transition: all 0.3s;
               width: 100%;
               z-index: 2;
               

               .title {
                  font-family: $perpetua;
                  font-size: 40px;
                  line-height: 1;
                  text-align: center;

                  @include for-size(phone) {
                     font-size: 55px;
                  }
               }
               
               .subtitle {
                  display: none;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: left;
               }

               .cta {
                  display: none;
                  text-align: center;
               }
            }

            .cover {
               background-color: #5EC1AE;
               mix-blend-mode: multiply;
               opacity: 0.7;
               height: 100%;
               position: absolute;
               transition: all 0.3s;
               width: 100%;
               z-index: 1;
            }
         }
      }
   }

   .people-section {
      margin: 0 auto;
      max-width: 1128px;  
      width: 100%;
               
      @include for-size(tablet) {
         padding: 0 20px;
      }
      
      p {
         color: #4B75A0;
         font-family: $perpetua;
         font-size: 55px;
         line-height: 65px;
         margin-top: 0;
         text-align: center;  

         @include for-size(phone) {
            font-size: 35px;
            line-height: 30px;
         }
      }
   }
}