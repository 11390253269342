@mixin for-size($size) {
   @if $size == phone {
     @media (max-width: 400px) { @content; }
   } @else if $size == tablet {
     @media (max-width: 1000px) { @content; }
   } @else if $size == desktop {
     @media (max-width: 1200px) { @content; }
   } @else if $size == desktop-small {
     @media (max-width: 1500px) { @content; }
   } @else if $size == big-desktop {
     @media (min-width: 1800px) { @content; }
   }
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;  /* stops bg color from leaking outside the border: */
  }
  
  @mixin transition($transition...){
      -moz-transition: $transition;
      -webkit-transition: $transition;
      transition: $transition;
  }
  
  @mixin transition_default(){
      @include transition(all .2s linear);
  }
  
  @mixin transform($transforms) {
      -moz-transform: $transforms;
      -o-transform: $transforms;
      -ms-transform: $transforms;
      -webkit-transform: $transforms;
      transform: $transforms;
  }
  
  @mixin shadow($params){
      -webkit-box-shadow: $params;
      -moz-box-shadow: $params;
      box-shadow: $params;
  }
  
  @mixin background-cover($url){
      background-image:url($url);
      background-repeat:no-repeat;
      background-size:cover;
  }
  
  @mixin gradient($top, $bottom){
      background: $top; /* Old browsers */
      background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
      background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
  }

  @mixin gradient-h($left, $right){
        background: $left; /* Old browsers */
        background: -moz-linear-gradient(left, $left 0%, $right 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $left 0%,$right 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $left 0%,$right 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$left', endColorstr='$right',GradientType=1 ); /* IE6-9 */
  }
  
  @mixin clear(){
      &:after{
          display:block;
          content:'';
          clear:both;
      }
  }
  
  @mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
  }
  
  