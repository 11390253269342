.services {
   &__hero {
      background-position: center;
      background-size: cover;
      display: grid;
      height: 350px;
      margin-bottom: 80px;
      position: relative;
      width: 100%;

      &__overlay {
         @include gradient-h($gradient-hero-left, $gradient-hero-right);
         height: 100%;
         left: 0px;
         opacity: .35;
         position: absolute;
         top: 0px;
         width: 100%;
      }

      &__copy {
         display: block;
         position: relative;
         margin: auto;
         max-width: 750px;

         @media screen and (max-width:$portrait) {
            padding: 10% 10% 0 0;
         }

         @media screen and (max-width:$mobile) {
            bottom: 20px;
            left: 20px;
            padding: 10% 10% 0px 0;
            position: absolute;
            width: auto;
         }

         h2 {
            color:$white;
            display: inline-block;
            font-family: $perpetua;
            font-weight: initial;
            font-size: 70px;
            line-height: 1;
            text-align: center;
         }
      }
   }
}