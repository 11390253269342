.content_quote{
    position:relative;
    .col{
        display: inline-block;
        vertical-align: top;
        width:49.5%;

        @media screen and (max-width:$mobile){
            width:100%;
        }

        &:first-child{
            padding-right:5%;

            @media screen and (max-width:$mobile){
                padding-right:0px;
            }
        }

        .quote{
            position:relative;
            display: block;
            max-width:290px;

            p{
                font-family: $helvetica-bold;
            }

            .author{
                position:relative;
                font-family: $helvetica-bold;
                text-transform: uppercase;
                font-size:14px;

                &__heading{
                    display: block;
                    font-family: $helvetica-light;
                    font-style: italic;
                    font-size:12px;
                    color:$light-gray;
                    padding-bottom:4px;
                }

                &__photo{
                    padding-left:40px;

                    &__container{
                        position:absolute;
                        top:0px;
                        left:0px;
                        width:30px;
                        height:30px;
                        @include border-radius(50%);
                        background-size:cover;
                        background-position:center;
                    }
                }
            }
        }
    }
}