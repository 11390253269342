.contact {
   &__form {
      background-color: #4B75A0;

      .container {
         padding: 60px 30px;

         @media screen and (max-width:$mobile) {
            padding: 0px 0px 30px;
         }
      }

      &__img {
         position: absolute;
         width: 50%;
         height: 75%;
         top: 50%;
         right: 5%;
         @include transform(translateY(-50%));
         background-size: cover;
         background-position: center;

         &__header {
            display: none;
            position: absolute;
            left: 30px;
            bottom: 30px;

            h1 {
               color: $white;
            }

            @media screen and (max-width:$mobile) {
               display: block;
            }
         }

         @media screen and (max-width:$portrait) {
            width: 40%;
            height: 50%;
            top: 40%;
         }

         @media screen and (max-width:$mobile) {
            position: relative;
            width: 100%;
            height: auto;
            top: auto;
            right: auto;
            @include transform(none);
            padding-bottom: 45%;
         }
      }

      &__form {

         display: block;
         position: relative;
         width: 35%;

         @media screen and (max-width:$portrait) {
            width: 50%;
         }

         @media screen and (max-width:$mobile) {
            padding: 30px;
            width: 100%;
         }

         h1 {
            color: $white;
            font-size: 28px;
            letter-spacing: 2px;
            line-height: 34px;
            padding-bottom: 0px;
            width: 80%;

            @media screen and (max-width:$mobile) {
               display: none;
            }

            &+p {
               margin-top: 0px;
            }
         }

         form {
            margin-top: 30px;

            .captcha_placeholder {
               border: 1px solid $white;
               color: $white;
               display: block;
               font-family: $helvetica-light;
               font-size: 16px;
               margin-bottom: 20px;
               padding: 20px;
               position: relative;
               text-align: center;
               text-transform: uppercase;
               width: 100%;
            }

            input {
               display: block;
               position: relative;
               width: 100%;
               @include gradient-h($gradient-footer-left, $gradient-footer-right);
               border: 1px solid $gradient-footer-left;
               color: $white;
               padding: 20px;
               //margin-bottom:20px;
               font-size: 16px;
               font-family: $helvetica-light;
               @include transition(all .15s linear);

               &:focus {
                  border: 1px solid $white;
               }

               &[type="submit"] {
                  text-transform: uppercase;
                  font-family: $helvetica-bold;
                  cursor: pointer;
                  background: white;
                  color: $gradient-footer-left;

                  &:hover {
                     background: $gradient-footer-left;
                     color: white;
                     border: 1px solid white;
                  }
               }
            }

            ::placeholder {
               /* Chrome, Firefox, Opera, Safari 10.1+ */
               color: $white;
               opacity: 1;
               /* Firefox */
               text-transform: uppercase;
               font-family: $helvetica-bold;
            }

            :-ms-input-placeholder {
               /* Internet Explorer 10-11 */
               color: $white;
               text-transform: uppercase;
               font-family: $helvetica-bold;
            }

            ::-ms-input-placeholder {
               /* Microsoft Edge */
               color: $white;
               text-transform: uppercase;
               font-family: $helvetica-bold;
            }
         }

         form.wpcf7-form>p {
            margin-bottom: 0px;
         }
      }
   }

   &__details {

      background-color: $white;

      .container {
         padding: 40px 30px;
      }

      .col {
         display: inline-block;
         vertical-align: top;
         width: 35%;

         &:first-child {
            width: 38%;
         }

         &:last-child {
            width: 25%;
         }

         @media screen and (max-width:$content-break) {
            display: block;
            width: 100%;
            margin-bottom: 40px;
         }

         h3 {
            font-family: $helvetica-bold;
         }

         p {
            line-height: 24px;
         }

         a {
            color: $copy;
            @include transition(color .3s linear);

            &:hover {
               color: $blue;
            }
         }
      }
   }

   &__map {
      position: relative;
      display: block;
      width: 100%;
      padding-bottom: 30%;
      background-size: cover;
      background-position: center;

      @media screen and (max-width:$content-break) {
         padding-bottom: 45%;
      }

      @media screen and (max-width:$mobile) {
         padding-bottom: 100%;
      }
   }


   .wpcf7-form {
      .wpcf7-form-control-wrap {
         display: block;
         margin-bottom: 20px;

         span.wpcf7-not-valid-tip {
            color: $red;
            font-family: $helvetica-light;
            font-size: 12px;
            margin-top: 6px;
         }
      }
   }

   div.wpcf7-response-output {
      margin: 0px;
      padding: 10px;
      font-family: $helvetica-light;
      font-size: 12px;
   }

   div.wpcf7-validation-errors {
      border: 1px solid $red;
      color: $red;
   }

   div.wpcf7-mail-sent-ok {
      border: 1px solid $dark-blue;
   }
}