.heading_copy{
    position:relative;

    .col{
        position:relative;
        vertical-align: top;
        width:49%;
        display: inline-block;

        @media screen and (max-width:$content-break){
            width:49%;
        }

        @media screen and (max-width:$mobile){
            width:100%;
        }

        &__70{
            width:69.5%;

            @media screen and (max-width:$content-break){
                width:49%;
            }

            @media screen and (max-width:$mobile){
                width:100%;
            }
        }

        &__30{
            width:29.5%;

            @media screen and (max-width:$content-break){
                width:49%;
            }

            @media screen and (max-width:$mobile){
                width:100%;
            }
        }

        &:first-child{
            h2{
                margin-top:0px;
                font-family:$perpetua;
                font-size:32px;
                padding-right:15%;

                @media screen and (max-width:$portrait){
                    padding-right:5%;
                }
            }
        }

        p{
            line-height:22px;
            font-size:16px;
        }
    }

    &__ol{
        list-style-type: none;
        margin:0px;
        padding:0px;

        li{
            display: block;
            padding:0px 0px 20px 0px;
            margin-bottom:20px;
            margin-left:40px;
            position:relative;
            font-family:$helvetica-bold;
            border-bottom:1px solid $light-blue;
            font-size:14px;

            .num{
                color: $blue;
                position:absolute;
                left:-40px;
                top:-2px;
                font-size:38px;
                font-family: $perpetua;
            }
        }
    }
}