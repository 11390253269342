.grid {
   &__container {
      position: relative;
      display: block;
      margin-top: 30px;
   }

   &__item {
      position: relative;
      width: 31.5%;
      margin-right: 2%;
      margin-bottom: 40px;
      padding-bottom: 40%;
      display: inline-block;
      vertical-align: top;
      background-size: cover;
      background-position: center;

      @media screen and (max-width:$content-break) {
         width: 100%;
         max-width: 400px;
         margin-right: 0px;
         display: block;
         margin-right: auto;
         margin-left: auto;
         margin-bottom: 80px;
         padding-bottom: 60%;
      }

      @media screen and (max-width:$mobile) {
         padding-bottom: 110%;
      }

      &:nth-child(3n) {
         margin-right: 0px;

         @media screen and (max-width:$content-break) {
            margin-right: auto;
         }
      }

      &__content {
         padding: 20px;
         display: block;
         position: absolute;
         left: 0px;
         bottom: -30px;
         min-height: 60%;
         width: 95%;
         background: $white;

         @media screen and (max-width:$portrait) {
            min-height: 70%;
         }

         &__copy {
            position: relative;
            display: block;
            padding: 0px 20px 30px 20px;
            font-family: $perpetua;

            &.with_icon {
               padding: 0px 10px 50px 40px;

               h3:first-of-type {
                  &:before {
                     display: block;
                     content: '';
                     position: absolute;
                     left: -38px;
                     top: -5px;
                     width: 30px;
                     height: 25px;
                     background-image: url('/wp-content/themes/hudson_advisors/assets/img/logo_icon_gray.png');
                     background-size: contain;
                     background-position: center;
                     background-repeat: no-repeat;

                     @media screen and (max-width:$content-break) {
                        position: relative;
                        top: auto;
                        left: auto;
                        margin-bottom: 6px;
                     }
                  }
               }
            }

            &>h3 {
               position: relative;
               margin-top: 0px;
               margin-bottom: 8px;
               font-size: 14px;
               color: $blue;
               font-family: $helvetica-bold;
               letter-spacing: 2px;
            }

            p {
               font-family: $perpetua;
               font-size: 22px;
               line-height: 22px;

               &:first-of-type {
                  margin-top: 0px;
               }

               &:last-of-type {
                  margin-bottom: 0px;
               }

               @media screen and (max-width:$portrait) {
                  font-size: 16px;
                  line-height: 18px;
               }

               @media screen and (max-width:$content-break) {
                  font-size: 24px;
                  line-height: 28px;
               }
            }
         }

         .read_more {
            position: absolute;
            display: block;
            left: 40px;
            bottom: 20px;

            &.indented {
               left: 60px;
            }
         }
      }
   }

   &__pagination {
      display: none;
      margin-top: 10px;
      position: relative;
      text-align: center;

      ul {
         list-style-type: none;
         margin: 0px;
         padding: 0px;

         li {
            display: inline-block;
            margin: 0px 10px;

            a {
               text-decoration: none;
               font-family: $helvetica-light;
               color: $dark-blue;
               font-size: 14px;
               @include transition(color 0.3s linear);

               &:hover {
                  color: $blue;
               }
            }
         }
      }
   }
}