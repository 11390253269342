.logo_rows {
   .wrapper {
      .text_column {
         align-items: center;
         border-bottom:1px solid black; 
         padding: 15px;
         display: flex;
         color: #767676;
         font-size: 25px;
         line-height: 40px;

         .img-wrap {
            width: 30%;

            img {
               display: block;
               max-width: 170px;
            }
         }

         .text-wrap {
            flex: 1;
         }

         @include for-size(tablet) {
            display: block;

            .img-wrap {
               margin-bottom: 15px;
            }
         }
      }
   }
}