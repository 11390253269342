.page.search{
    .pagination{
        text-align:center;
        margin-bottom:60px;

        a,span{
            position:relative;
            display: inline-block;
            margin: 0px 10px;
            text-decoration:none;
            font-family: $helvetica-light;
            color:$dark-blue;
            font-size:14px;
            @include transition(color 0.3s linear);
        }

        a:hover{
            color: $blue;

            &:after,&:before{
                color: $blue;
            }
        }

        a.prev{
            margin-right:20px;
            &:after{
                color: $blue;
                position: absolute;
                left:-8px;
                top:-19px;
                font-size:24px;
                font-weight:600;
                content:'\2190';
            }
        }

        a.next{
            &:after{
                position: absolute;
                left:-8px;
                top:-19px;
                font-size:24px;
                font-weight:600;
                content:'\2192';
                color: $blue;
            }
        }
    }
}

.page__content__search_results{
    padding:0px 20px;

    h2{
        font-family: $perpetua;
        font-size:26px;
        margin-bottom:10px;
        font-weight:500;

        &+p{
            margin-top:0px;
        }
    }
    p{
        font-family:$helvetica-light;
        font-size:16px;
        color:$copy;
    }
}