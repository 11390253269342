.text_columns {

	.wrapper {
		display: flex;

		@media screen and (max-width:$tablet){
			display: block;
		}
	}

	h2{
		margin-bottom:6px;
		margin-top:30px;
		font-family: $perpetua;
		font-size:60px;
		font-weight: normal;
		line-height: 1em;
		color: $dark-blue-2;

		@media screen and (max-width:$content-break){
			font-size:30px;
			margin-top:0px;
		}

		& + p{
			margin-top: 0;
		}
	}
	h3 {
		margin: 1em 0
	}

	p {
		font-size: 20px;
		margin: 1em 0;
		line-height: 1.4em;

		& > span {
			line-height: 1.4em;
			font-family: $perpetua;
		}
	}
	strong {
		font-family: $helvetica-bold;
    
	}
	.text_column {
		flex-grow: 1;
		flex-basis: 0;
		padding: 10px 5px;
		margin-right: 3%;
    p.border-top {
      border-top:3px solid #E8E7E7;
      margin:0;
      padding:25px 0;
    }
	}

	&.green_txt {

		.text_column {
			@media screen and (max-width:$tablet){
				border-top: 0 none;
			}
			&:first-of-type {
				@media screen and (max-width:$tablet){
					border-top: 2px solid $light-gray;
				}
			}
		}
		p {
			font-family: $helvetica-light;
			font-size: 25px;
			color: #767676;
			line-height: 40px;
		}
	}

	&.green_borders {

		.text_column {
			border-top: 3px solid $light-gray;
		}
	}
	&.wide_1st_col {

		.text_column {
			flex-basis: 40%;

			&:first-of-type {
				flex-basis: 60%;
			}
		}
	}
	&.fluid_width {

		.text_column {
			flex-basis: auto;
			align-self: center;
		}
	}
}



div.text_columns {
  display:flex;
  margin:0 0 50px 0;
  letter-spacing:0px;
  
	@media screen and (max-width:$tablet){
		display: block;
    margin:0 0 0px 0;
	}
  
	.text_column {
		flex-grow: 0;
		flex-basis: 50%;
    min-width:50%;
		padding: 10px 0px;
		margin-right: 6%;
    border:none;
    ul {
      padding:0;
       @media screen and (max-width:1164px){
         padding:0 0 0 20px;
       }
    }
    p {
      font-size:20px;
      line-spacing:28px;
      margin:.75em 0;
      letter-spacing:0px;
      color:#222944;
      opacity:.8;
      @media screen and (max-width:$mobile){
        letter-spacing:-1px;
      }
    }
    a {
      color: $blue;
      text-decoration:underline;
    }
	}

	&.green_txt {

		.text_column {
			border-top: 2px solid $light-gray;
			border-bottom: 2px solid $light-gray;


			@media screen and (max-width:$tablet){
				border-top: 0 none;
			}
			&:first-of-type {
				@media screen and (max-width:$tablet){
					border-top: 2px solid $light-gray;
				}
			}
		}
		p {
			font-family: $perpetua;
			font-size: 30px;
			color: $blue;
			line-height: 1.2em;
		}
	}

	&.green_border {

		.text_column p:first-of-type {
      max-width: 385px;
      padding-top: 30px;
      position:relative;
		}
    .text_column p:first-of-type:after {
      position:absolute;
      top:0;
      left:0;
      content:'';
      border-top: 2px solid $green;
      width:310px;
      z-index:1;
    }
	}
	&.wide_1st_col {

		.text_column {
			flex-basis: 40%;

			&:first-of-type {
				flex-basis: 60%;
			}
		}
	}
	&.fluid_width {

		.text_column {
			flex-basis: auto;
			align-self: center;
		}
	}
}

div.text_columns.no-margin {
  margin:0 0 0 0;
}

div.text_columns.fitthree {
   width: 100%;

   .text_column {
      border-top: 3px solid #CCC;
      flex: 1;
      min-width: initial;
		// flex-basis: 33%;
      // min-width:33%;
		padding: 10px 0px;
		// margin-right: 6%;

      &:after {
         width: 100% !important;
      }
   }
}