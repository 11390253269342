.hero {
   background-color: #CCC;
   background-position: center;
   background-size: cover;
   display: grid;
   height: 350px;
   position: relative;
   width: 100%;

   &__overlay {
      // @include gradient-h($gradient-hero-left, $gradient-hero-right);
      height: 100%;
      left: 0px;
      // opacity: .35;
      position: absolute;
      top: 0px;
      width: 100%;
   }

   &__copy {
      display: block;
      margin:auto;
      max-width:750px;
      position:relative;

      h2,
      p {
         font-size: 40px;
         line-height: 34px;

         @media screen and (max-width:$mobile) {
            font-size: 30px;
            line-height: 34px;
         }
      }

      h2 {
         color:$white;
         display: inline-block;
         font-family: $perpetua;
         font-weight: initial;
         font-size: 70px;
         line-height: 1;
         text-align: center;

         &:first-of-type {
            margin-bottom: 0px;
            padding-bottom: 10px;
            position: relative;
         }

         @include for-size(phone) {
            font-size: 40px;
         }
      }

      p {
         color: $white;
         font-family: $helvetica-light;
         line-height: 1.2em;
      }
   }
}