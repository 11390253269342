.page {

   &.no-hero {
      margin-top: 80px;
   }

   .container {
      position: relative;
      display: block;
      margin: auto;
      width: 100%;
      max-width: 1128px;

      @media screen and (max-width:1164px) {
         padding: 20px;
      }

      &__full {
         max-width: 100%;
      }

      &__heading {
         padding: 0px;

         p {
            font-family: $perpetua;
            font-size: 30px;
            line-height: 38px;
         }
      }

      &__white {
         background-color: $white;
      }

      section {
         margin-bottom: 20px;
         padding-top: 30px;

         &#commercial-real-estate,
         &#single-family-residential-real-estate {
         }
      }

      .grid {
         margin-top: 80px;
      }
   }

   .container.secondary {
      padding: 0px 0 0px 0;
   }

   .container.extra_padding {
      padding-top: 100px;
   }

   &__content {

      p {
         font-size: 16px;
         line-height: 22px;
      }

      &__intro {
         position: relative;
         width: 100%;
         max-width: 800px;
         padding: 40px 0 0 0;

         @media screen and (max-width:$mobile) {
            padding: 40px 0px 0px;
         }

         h1:first-of-type {
            font-size: 18px;
            margin-bottom: 0px;

            a {
               color: $dark-blue;
               text-decoration: none;

               &:hover {
                  color: $blue;
               }
            }
         }
      }

      &__padded {
         padding: 0px 20px 0px;

         @media screen and (max-width:$mobile) {
            padding: 0px 0px 0px;
         }
      }

      &__800 {
         max-width: 800px;
      }
   }

   .caption {
      font-family: $helvetica-light;
      font-size: 12px;
      margin-top: 6px;
   }
}

body.parent-about-hudson {

   .hero {
      height: 100vh;
      transition: transform 0.5s, margin 0.5s;
      margin-bottom: 0px;

   }

   &.scrolling .hero {
      transform: translateY(-100%);
   }

   section {
      opacity: 0 !important;
      margin-top: 80px;
      transition: opacity 0.5s, margin 0.5s;
   }

   &.reveal section {
      opacity: 1 !important;
      margin-top: 0;
   }

   section.location_grid {
      margin-top: 60px !important;
   }
}

.service-template-default {

   .page {
      .container {
         max-width: 1128px;
      }
   }

   .copy_block {
      max-width: 1128px;
   }
}

.markets {

   .text_columns {

      &:nth-of-type(2) {

         p {
            border-top: 2px solid $light-gray;
            padding-top: 1em;
         }
      }
   }
}



/* Tables
================================== */
.Rtable {
   display: flex;
   flex-wrap: wrap;
   margin: 0 0 3em 0;
   padding: 0;
}

.Rtable-cell {
   box-sizing: border-box;
   flex-grow: 1;
   width: 100%; // Default to full width
   padding: 0.8em 1.2em;
   overflow: hidden; // Or flex might break
   list-style: none;
   border: solid 1px #000;

   >h1,
   >h2,
   >h3,
   >h4,
   >h5,
   >h6 {
      margin: 0;
   }

   h3 {
      color: #000;
   }
}

/* Table column sizing
================================== */
.Rtable--2cols>.Rtable-cell {
   width: 50%;
}

.Rtable--3cols>.Rtable-cell {
   width: 33.33%;
}

.Rtable--4cols>.Rtable-cell {
   width: 25%;
}

.Rtable--5cols>.Rtable-cell {
   width: 20%;
}

.Rtable--6cols>.Rtable-cell {
   width: 16.6%;
}

/* Responsive
==================================== */
@media all and (max-width: 640px) {
   .Rtable--collapse {
      display: block;

      >.Rtable-cell {
         width: 100% !important;
      }

      >.Rtable-cell--foot {
         margin-bottom: 1em;
      }
   }
}

// Non-Flex modernizer fallback
.no-flexbox .Rtable {
   display: block;

   >.Rtable-cell {
      width: 100%;
   }

   >.Rtable-cell--foot {
      margin-bottom: 1em;
   }
}