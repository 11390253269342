#cookie-notice{
    border-top:1px solid $dark-blue-2;
    

    &.cookie-notice-hidden{
        .cookie-notice-container{
            display: none !important;
        }
    }

    .cookie-notice-container {
      display:flex !important;
      justify-content:space-between;
      
        .cn-text-container{
          flex-basis:50%;
          min-width:50%;
          text-align:left;
            a{
                color: $blue;

                &:hover{
                    text-decoration:underline;
                }
            }
        }

        .cn-buttons-container{
          flex-basis:50%;
          min-width:50%;
            margin-top:10px;
            display: block;
            text-align: center;

            .cn-button{
                @include border-radius(0px);
                background: transparent;
                border:1px solid $green;

                &:hover{
                    background:$green;
                }
            }
        }
    }
}