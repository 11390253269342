.copy_block {
   color: #767676;
   font-size: 25px;
   line-height: 40px;
   width: 100%;
   max-width: 1024px;

   h2 {
      color: #4B75A0;
      font-family: $perpetua;
      font-size: 55px;
      font-weight: normal;
      line-height: 65px;
      margin-bottom: 6px;
      margin-top: 30px;

      @media screen and (max-width:$content-break) {
         font-size: 30px;
         line-height: 36px;
         margin-top: 0px;
      }

      &+p {
         margin-top: 0;
      }
   }

   ul {
      list-style-type: none;

      li {
         font-family: $helvetica-light;
         font-size: 20px;
         line-height: 28px;
         padding-left: 12px;
         position: relative;

         &:before { 
            color: #767676;
            content: "•"; 
            display: inline-block;
            font-size: 14px;
            margin-left: -1em;
            position: absolute;
            top: -1px;
            width: 1em;
         }
      }
   }

   p>span {
      line-height: 1.4em;
      font-family: $perpetua;
   }

   div.quote {
      color: $blue;
      margin: 50px auto;
      width: calc(90%);
      padding: 30px 0;
      border-top: 2px solid $light-gray;
      border-bottom: 2px solid $light-gray;
      font-size: 36px;
      line-height: 40px;
      font-family: $perpetua;
   }

   p.teaser {
      font-size: 30px;
      line-height: 36px;
      font-family: $perpetua;
   }

}

.copy_block.with_accordion {
   max-width: 100% !important;
   width: 100%;
   margin: 0 auto 90px auto;
   position: relative;
   z-index: 1;

   .top-wrapper {
      margin: 0 auto;
      max-width: 1128px;
      position: relative;
      z-index: 2;
   }

   h3.name {
      color: #000;
      font-family: $perpetua;
      font-weight: 400;
      font-size: 40px;
      line-height: 60px;
      text-align: center;
      text-transform: none;
      letter-spacing: normal;
   }

   .teaser {
      p {
         font-family: $helvetica;
         font-size: 25px;
         line-height: 35px;

         a {
            color: $blue;
         }

         span.regular {
            color: #222944;
            opacity: .8;
            font-size: 20px;
            line-height: 28px;
            font-family: $helvetica-light;
         }
      }
   }

   .content {
      display: none;
      background-color: #fff;
      padding: 60px 0;
      top: -20px;
      position: relative;
      z-index: 1;
      width: 100%;

      .wrapper {
         margin: 0 auto;
         max-width: 1024px;

         p {
            font-size: 20px;
            line-height: 28px;
            font-family: $helvetica-light;
            opacity: .8;

            a {
               color: $blue;
               text-decoration: underline;
            }
         }

         p.green {
            color: $blue;
            font-size: 30px;
            line-height: 36px;
            font-family: $perpetua;
         }

         p strong {
            font-family: $helvetica-bold;
         }

         .text_columns.green_borders {
            .text_column {
               border-top: 0px;
               position: relative;
            }

            .text_column:after {
               content: "";
               position: absolute;
               left: 0;
               top: 0;
               height: 3px;
               width: 160px;
               border-top: 3px solid $light-gray;
            }
         }
      }
   }

   @media screen and (max-width:1164px) {
      .top-wrapper {
         padding: 0px 20px;
      }

      .content {
         padding: 60px 20px;
      }
   }


   .toggle-btn {
      align-items: center;
      background-color: #fff;
      border: 2px solid $blue;
      box-sizing: border-box;
      color: $blue;
      display: inline-flex;
      font-family: $helvetica-bold;
      line-height: 1;
      font-size: 14px;
      justify-content: space-between;
      min-width: 185px;
      padding: 12px 25px 10px 18px;
      transition: all ease .3s;

      span {
         letter-spacing: 1px;
         font-family: $helvetica-bold;
      }

      span.closed {
         display: inline-block;
      }

      span.open {
         display: none;
      }

      svg {
         transition: all ease .3s;

         polygon {
            transition: all ease .3s;
         }

         g.Button {
            transition: all ease .3s;
         }
      }
   }

   .toggle-btn:hover {
      transition: all ease .3s;
      background-color: $blue;
      color: #fff;

      svg {
         transition: all ease .3s;

         polygon {
            fill: #fff;
            transition: all ease .3s;
         }

         g.Button {
            fill: $blue;
            transition: all ease .3s;
         }
      }
   }
}

.copy_block.with_accordion.open {
   .toggle-btn {

      span.closed {
         display: none;
      }

      span.open {
         display: inline-block;
      }

      svg {
         transform: rotate(180deg);
         transition: all ease .3s;
      }
   }
}

#underwriting {
   margin-bottom: 0px !important;
}