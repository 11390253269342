.people_grid {
   .grid_container {
      position: relative;
   }

   .person {
      display: inline-block;
      margin-bottom: 100px;
      margin-right: 3%;
      position: relative;
      vertical-align: top;
      width: 30%;

      @media screen and (max-width:$content-break) {
         width: 48%;
         margin-right: 3%;
      }

      @media screen and (max-width:$mobile) {
         width: 100%;
         margin-right: 0;
      }

      &:nth-child(3n) {
         margin-right: 0px;

         @media screen and (max-width:$content-break) {
            margin-right: 3%;
         }

         @media screen and (max-width:$mobile) {
            margin-right: 0;
         }
      }

      &:nth-child(2n) {
         @media screen and (max-width:$content-break) {
            margin-right: 0px;
         }

         @media screen and (max-width:$mobile) {
            margin-right: 0;
         }
      }

      &__img {
         background: #707070;
         position: relative;
         background-size: cover;
         background-position: center;
         padding-bottom: 100%;
      }

      &__location {
         color: #89AEBE;
         font-family: $helvetica;
         font-size: 16x;

         @media screen and (max-width:$portrait) and (min-width:$tablet) {
            font-size: 12px;
         }
      }

      &__info {
         color: $dark-blue-2;
         font-family: $helvetica;
         font-size: 18px;
         font-weight: 500;
         padding: 8px 0px;

         @media screen and (max-width:$portrait) and (min-width:$tablet) {
            font-size: 14px;
         }

         &__name {
         }

         &__title {
            color: #89AEBE;
         }
      }

      &__btn {
         cursor: pointer;
      }
   }

   .people_modal {

      position: fixed;

      width: 0px;
      height: 0px;
      top: -100px;
      overflow: hidden;
      opacity: 0;
      z-index: 0;
      @include transition(opacity .3s linear);

      .modal_bio,
      .modal_name,
      .modal_title,
      .modal_photo {
         @include transition(opacity .15s linear);
      }

      .modal_bio {
         p {
            font-size: 14px;
         }

         .bio_intro,
         .bio_intro p {
            font-size: 22px;
            font-family: $perpetua;
         }
      }

      .loading {
         opacity: 0;
      }

      &.loaded {
         width: 100%;
         height: 100%;
         top: 0px;
         left: 0px;
         opacity: 1;
         z-index: 999;
      }

      &__background {
         position: absolute;
         top: 0px;
         left: 0px;
         width: 100%;
         height: 100%;
         background: rgba(0, 0, 0, .5);
      }

      &__container {
         width: 100%;
         height: 100%;
         max-width: 1024px;
         max-height: 768px;
         top: 50%;
         left: 50%;
         @include transform(translate(-50%, -50%));
         position: absolute;
         background: $white;

         @media screen and (max-width:$mobile) {
            max-width: 100%;
            max-height: 100%;
            padding-top: 120px;
            top: 0px;
            left: 0px;
            @include transform(none);
            overflow-y: auto;
         }
      }

      &__header {
         position: relative;
         height: 25%;
         @include gradient-h($gradient-sidebar-left, $gradient-sidebar-right);

         @media screen and (max-width:$mobile) {
            height: 100px;
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            z-index: 1;
         }

         &__name {
            position: absolute;
            width: 55%;
            left: 40%;
            bottom: 16px;
            font-family: $perpetua;
            color: $white;
            font-size: 24px;

            @media screen and (max-width:$mobile) {
               position: relative;
               left: auto;
               bottom: auto;
               padding: 20px;
               width: auto;
            }

            h2 {
               font-size: 32px;
               font-family: $perpetua;
               color: $blue;
               margin-bottom: 0px;

               @media screen and (max-width:$mobile) {
                  margin: 0px;
               }
            }
         }
      }

      &__content {
         position: absolute;
         width: 55%;
         left: 40%;
         height: 65%;
         top: 30%;
         padding: 10px 20px 10px 0px;
         overflow-y: auto;

         @media screen and (max-width:$mobile) {
            position: relative;
            width: 80%;
            margin: 0px auto;
            overflow-y: visible;
            padding: 10px 0px 30px;
            height: auto;
            left: auto;
            top: auto;
         }

         &:before {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 60px;
            height: 2px;
            background: $green;
            content: '';
         }
      }

      &__photo {
         position: absolute;
         left: 4%;
         top: 5%;
         width: 30%;
         padding-top: 30%;
         background-size: cover;
         background-position: center;

         @media screen and (max-width:$mobile) {
            position: relative;
            left: auto;
            top: auto;
            width: 80%;
            padding-top: 80%;
            margin: auto;
            margin-top: 20px;
         }

         &__ribbon {
            position: absolute;
            padding: 6px 30px;
            bottom: 20px;
            left: -4px;
            width: auto;
            min-width: 65%;
            max-width: 75%;
            background-color: $blue;
            color: $white;
            font-family: $helvetica-light;
            font-size: 16px;

            &.hidden {
               display: none;
            }

            @media screen and (max-width:$portrait) and (min-width:$mobile + 1) {
               font-size: 12px;
               max-width: 90%;
            }
         }
      }

      &__nav {
         position: absolute;
         width: 15%;
         top: 48%;
         left: 19%;
         background: $white;

         @media screen and (max-width:$portrait) {
            width: 25%;
            left: 9%;
            top: 40%;
            height: 40px;
         }

         @media screen and (max-width:$mobile) {
            position: relative;
            top: auto;
            left: auto;
            width: 80%;
            margin: 20px auto;
         }

         a {
            border: 1px solid $green;
            color: $white;
            cursor: pointer;
            display: block;
            float: left;
            font-family: $helvetica-bold;
            font-size: 11px;
            height: 24px;
            position: relative;
            text-align: center;
            text-align: center;
            width: 50%;

            &:last-child {
               border-left: none;
            }

            @media screen and (max-width:$mobile) {
               height: 35px;
               font-size: 14px;
            }

            div {
               display: block;
               position: absolute;
               width: 100%;
               top: 50%;
               left: 0px;
               @include transform(translateY(-50%));

               &:first-child {
                  opacity: 1;
               }

               &:last-child {
                  opacity: 0;
               }
            }

            @include transition(all 0.3s linear);

            &:hover {
               background-color: $blue;

               div {
                  &:first-child {
                     opacity: 0;
                  }

                  &:last-child {
                     opacity: 1;
                  }
               }
            }
         }
      }

      &__close {
         color: $white;
         right: 20px;
         top: 10px;
         font-size: 54px;
         position: absolute;
         display: block;
         z-index: 2;

         @media screen and (max-width:$mobile) {
            position: fixed;
         }

         a {
            color: $white;
            @include transition(all 0.3s linear);
            cursor: pointer;

            &:hover {
               color: $blue;
            }
         }
      }
   }
}