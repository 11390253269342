.real-estate,
.credit,
.corporate-equity,
.administration {
   .page {
      .container {
         .people_grid {
            margin-top: 100px;
            
            .grid_container {
               .person {
                  width: 20%;
                  
                  &:nth-child(3n) {
                     margin-right: 3%;
                  }
                  
                  &:nth-child(4n) {
                     margin-right: 0px;
                     
                     @media screen and (max-width:$content-break) {
                        margin-right: 3%;
                     }
                     
                     @media screen and (max-width:$mobile) {
                     margin-right: 0;
                     }
                  }
               }
            }
         }
      }
   }
}