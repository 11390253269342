.location_grid{

    margin-top:60px;

    &__region{
        position:relative;
        background-color:$gray-transparent;
        padding:30px;
        margin-bottom:60px;

        &__name{
            position:absolute;
            left:0px;
            top:-20px;
            background-color: $blue;
            font-family: $helvetica-bold;
            font-size:14px;
            text-transform:uppercase;
            padding:8px 30px;
            min-width:120px;
            color:$white;
        }

        &__locations{
            position:relative;
            display: block;

            .location{
                position:relative;
                display: inline-block;
                width:16%;
                margin-right:3.8%;
                margin-bottom:0px;
                vertical-align: top;

                @media screen and (max-width:$portrait){
                    width: 20%;
                    margin-right:5.5%;
                }

                @media screen and (max-width:$tablet){
                    width: 30%;
                    margin-right:4%;
                }

                @media screen and (max-width:$location-break){
                    width: 45%;
                    margin-right:4.5% !important;
                }

                @media screen and (max-width:$mobile){
                    width: 100%;
                    margin-right:0% !important;
                }

                &:nth-child(2n){
                    @media screen and (max-width:$location-break){
                        margin-right:0px !important;
                    }
                }

                &:nth-child(3n){
                    @media screen and (max-width:$tablet){
                        margin-right:0px;
                    }

                }
                
                &:nth-child(4n){
                    @media screen and (max-width:$portrait){
                        margin-right:0px;
                    }

                    @media screen and (max-width:$tablet){
                        margin-right:4%;
                    }

                }
    
                &:nth-child(5n){
                    margin-right:0;

                    @media screen and (max-width:$portrait){
                        margin-right:5.5%;
                    }

                    @media screen and (max-width:$tablet){
                        margin-right:4%;
                    }

                    @media screen and (max-width:$location-break){
                        margin-right:4.5%;
                    }
                }

                &__city{
                    font-family: $perpetua-bold;
                    text-transform:uppercase;
                    font-size:18px;
                    letter-spacing: 0px;
                }

                &__name,&__address,&__phone{
                    letter-spacing: 0px;
                    font-size:14px;
                    font-family: $helvetica-light;
                    margin-bottom:16px;
                }
            }
        }
    }
}