.press{

    &__item{
        position:relative;
        display: block;
        margin:0px 0px 20px;
        padding:16% 0px 0px 20px;

        @media screen and (max-width:$portrait) {
            padding:20% 20px 0px 20px;
        }

        @media screen and (max-width:$mobile-press) {
            padding:40% 20px 0px 20px;
        }

        &__bg{
            padding-bottom:30%;
            position:absolute;
            display: block;
            left:0px;
            top:0px;
            width:100%;
            background-size:cover;
            background-position:center;

            @media screen and (max-width:$mobile-press) {
                padding-bottom:100%;
            }
        }

        &__content{
            padding:20px 30px;
            width:60%;
            min-height:210px;
            position:relative;
            display: block;
            background-color:$white;

            @media screen and (max-width:$portrait) {
                width:100%;
            }

            &__date{
                color: $blue;
                font-family: $helvetica-bold;
                text-transform:uppercase;
                font-size:14px;
                letter-spacing:2px;
            }

            &__title{
                h2{
                    font-family: $perpetua;
                    font-size:32px;
                    font-weight:400;
                }
            }
        }
    }

    &__content{
        position:relative;
        display: block;

        &__container{
            position:relative;
            display: block;
            padding:0px 20px;
            max-width:600px;
            margin-bottom:100px;

            p,h2{
                font-size:16px;
                line-height:22px;
            }

            h2{
                text-transform:uppercase;
                color: $blue;
                font-family: $helvetica-bold;
                margin-top:30px;
            }

            blockquote{
                margin:30px 0px;
                border-top:1px solid $gray-transparent;
                border-bottom:1px solid $gray-transparent;
                padding:20px 0px;
                font-family: $perpetua-bold;
                font-size:28px;

                p{
                    font-family: $perpetua;
                    font-size:28px; 
                    line-height:32px;

                    &:first-of-type{
                        margin-top:0px;
                    }
                    &:last-of-type{
                        margin-bottom:0px;
                    }
                }
            }
        }
    }

    &__release{
        @media screen and (max-width:$mobile){

            section.press{
                margin-bottom:0px;
            }

            .page__content__intro{
                display: none;
            }

            .container{
                padding:0px;
            }

            .press__item{
                padding:0px;
                margin:0px;

                &__bg{
                    position:relative;
                    padding-bottom:45%;
                }

                &__content{
                    background-color:$body;
                    min-height:0px;

                    &__title{
                        h2{
                            margin-bottom:0px;
                            
                        }
                    }
                }
            }

            .press__content__container{
                padding:0px 30px;
            }
        }
    }

    &__filters{
        padding:0px 20px;
        text-transform:uppercase;

        a{
            display: inline-block;
            margin:0px 10px;
            color:$copy;
            font-family: $helvetica-light;

            &:hover{
                text-decoration: underline;
            }

            &:first-of-type{
                margin-left:0px;
            }

            &:last-of-type{
                margin-right:0px;
            }

            &.active{
                font-family: $helvetica-bold;
            }
        }
    }
}

#press_grid{
    .grid__container{
        opacity:0;
        @include transition(opacity 0.3s linear);
    }

    &.loaded{
        .grid__container{
            opacity:1;
        }
    }

    .paginationjs-prev,.paginationjs-next{
        a{
            color: $blue;
        }

        &.disabled a{
            opacity:.5;
        }
    }

    .paginationjs-prev a{
        display: inline-block;
        position:relative;
        &:before{
            position: absolute;
            left:-19px;
            top:-19px;
            font-size:24px;
            font-weight:600;
            content:'\2190';
        }   
    }

    .paginationjs-next a{
        display: inline-block;
        position:relative;
        &:after{
            position: absolute;
            left:-8px;
            top:-19px;
            font-size:24px;
            font-weight:600;
            content:'\2192';
        }   
    }
}