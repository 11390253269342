.content_image{
    position:relative;
    .col{
        display: inline-block;
        vertical-align: top;
        width:49.5%;

        @media screen and (max-width:$mobile){
            width:100%;
        }

        &:first-child{
            padding-right:5%;

            @media screen and (max-width:$content-break){
                width:64%;
            }

            @media screen and (max-width:$mobile){
                width:100%;
                padding-right:0px;
            }
        }
    }

    &__img{
        position:relative;
        display: inline-block;

         img{
             width:auto;
             max-width:100%;
         }
    }
}