.our-people {
   .teams-nav {
      display: block;
   }
}

.teams-nav {
   background-color: rgba(122, 110, 102, 0.07);
   display: none;

   @include for-size(phone) {
      background-color: #F9FAFC;
   }

   .container {
      .section-title {
         padding: 20px 0 0 0;
      }

      .links {
         display: flex;
         justify-content: space-between;
         list-style-type: none;
         margin: 0;
         padding: 60px 0;

         @include for-size(phone) {
            flex-direction: column;
            padding: 20px 0;
         }

         .link {
            flex: 1;
            height: 170px;

            &.link:not(:last-child) {
               border-right: 1px solid #4B75A0;

               @include for-size(phone) {
                  border-bottom: 1px solid #4B75A0;
                  border-right: none !important;
               }
            }

            &.link:not(:first-child) {
               padding-left: 20px;
            }

            @include for-size(phone) {
               padding: 30px 0 !important;
            }

            a {
               color: $copy;
               display: flex;
               flex-direction: column;

               @include for-size(phone) {
                  align-items: center;
                  flex-direction: row;
                  justify-content: space-between;
               }

               &:hover {
                  .icon {
                     margin-left: 20px;
                  }
               }

               .label {
                  font-family: $perpetua;
                  font-size: 35px;
                  margin-bottom: 40px;

                  @include for-size(phone) {
                     margin-bottom: 0;
                  }
               }

               .icon {
                  @include transform(rotate(180deg));
                  transition: 0.3s;
               }
            }
         }
      }
   }
}

.footer {
   background-color: $black;
   height: 200px;
   padding: 0px 0 0px 30%;
   position: relative;
   width: 100%;

   &__logo {
      position: absolute;
      top: 40px;
      left: 60px;

      @media screen and (max-width:$portrait) {
         display: none;
      }
   }

   @media screen and (max-width:$large) {
      height: 230px;
   }

   @media screen and (max-width:$portrait) {
      position: relative;
      height: auto;
      padding: 30px 50px;
   }

   &__nav {
      position: absolute;
      left: 32%;
      top: 50%;
      @include transform(translateY(-50%));
      //min-width:50%;

      @media screen and (max-width:$xlarge) {
         left: 40%;
      }

      @media screen and (max-width:$large) {
         left: 35%;
         top: 40%;
      }

      @media screen and (max-width:$portrait) {
         position: relative;
         @include transform(none);
         top: 0px;
         left: 0px;
      }

      a i {
         font-size: 32px;
      }

      ul {
         margin: 0px;
         padding: 0px;
         list-style-type: none;
      }

      &>ul {
         display: inline-block;
         vertical-align: top;
         margin-right: 60px;

         @media screen and (max-width:$large) {
            margin-right: 30px;
         }

         @media screen and (max-width:$portrait) {
            display: block;
            margin-right: 0px;
            margin-bottom: 10px;
         }

         &.portrait {
            display: none;

            @media screen and (max-width: $portrait) {
               display: block;
            }
         }

         li {

            &.portrait {
               display: none;

               @media screen and (max-width: $portrait) {
                  display: block;
               }
            }

            padding-bottom: 0px;

            &:last-child {
               padding-bottom: 0px;
            }

            a {
               color: $white;
               text-decoration: none;
               font-weight: 600;
               font-size: 13px;
               @include transition(color 0.3s linear);
               font-family: $helvetica-bold;

               &:hover {
                  color: $blue;
               }
            }

            ul {
               margin-top: 0;
               display: block;

               @media screen and (max-width:$portrait) {
                  margin-bottom: 10px;
               }

               li a {
                  font-weight: normal;
                  font-family: $helvetica-light;
               }
            }
         }
      }

   }

   &__contact {
      position: absolute;
      top: 50%;
      right: 40px;
      width: auto;
      width: 270px;

      @include transform(translateY(-50%));

      @media screen and (max-width:$large) {
         right: 30px;
         max-width: 240px;
         top: 36%;
      }

      @media screen and (max-width:$landscape-small) {
         right: 20px;
         max-width: 200px;
      }

      @media screen and (max-width:$portrait) {
         position: relative;
         margin-top: 30px;
         top: 0px;
         right: auto;
         max-width: 100%;
         width: 100%;
         @include transform(none);
      }

      p {
         color: $white;
         font-size: 22px;
         font-family: $helvetica-light;

         &:first-of-type {
            margin-top: 0px;
         }

         @media screen and (max-width:$large) {
            font-size: 16px;
            line-height: 22px;
         }

         @media screen and (max-width:$portrait) {
            display: none;
         }
      }

      .btn_contact {
         @include transition(all 0.3s linear);
         border: 2px solid $white;
         color: $white;
         display: inline-block;
         font-size: 16px;
         line-height: 1;
         padding: 18px;
         text-decoration: none;
         text-align: center;
         text-transform: uppercase;
         width: 270px;
         
         &:hover {
            background-color: #41D79E;
            color: #fff;
         }
         
         @media screen and (max-width:$landscape-small) {
            font-size: 12px;
         }

         @media screen and (max-width:$portrait) {
            width: 100%;
            max-width: 300px;
         }
      }
   }

   &__legal {
      position: absolute;
      left: 60px;
      bottom: 20px;

      @media screen and (max-width:$portrait) {
         position: relative;
         left: auto;
         bottom: auto;
         margin-top: 20px;
      }

      ul {
         list-style-type: none;
         margin: 0px;
         padding: 0px;
         line-height: normal;

         li {
            display: inline-block;
            padding: 0px 4px;
            color: $white;
            font-size: 8px;
            border-right: 1px solid $white;

            p {
               font-size: 8px;
               color: $white;
            }


            &:last-child {
               padding: 0px 0px 0px 6px;
               border-right: none;
            }

            a {
               color: $white;
               text-decoration: none;
               font-size: 8px;
               text-transform: uppercase;
               @include transition(color 0.3s linear);

               &:hover {
                  color: $blue;
               }
            }
         }
      }
   }
}

a.cn-revoke-cookie {
   padding: 0px !important;
   background: transparent !important;
   text-decoration: underline !important;
   color: #A5A9B9 !important;
   font-size: 12px;
   font-weight: bold;
   font-family: $helvetica-light;
   @include transition(color 0.3s linear);

   &:hover {
      color: $blue !important;
   }
}