/* COLORS */
$body:#F9FAFC;
$white: #FFF;
$black: #000;
$green: #41D79E;
$dark-blue: #090F24;
$dark-blue-2: #222944;
$light-blue: rgba(128,151,177,.4);
$light-gray:#CCC;
$gradient-header-left:#090F24;
$gradient-header-right:#01020F;
$gradient-sidebar-left:#090F24;
$gradient-sidebar-right:#01020F;
$gradient-hero-left: #334F5E;
$gradient-hero-right: #222944;
$gradient-footer-left: #334F5E;
$gradient-footer-right: #222944;
$gray-transparent: rgba(216,216,216,.2);
$red:#660000;
$blue: #4B75A0;

.blue {
   color: $blue !important;
}

$copy: #1A1818;

/* BREAKPOINTS */
$xlarge: 1400px;
$large: 1200px;
$landscape:1024px;
$landscape-small:1023px;
$portrait:900px;
$tablet:768px;
$content-break:767px;
$location-break:660px;
$mobile:600px;
$mobile-press:500px;

/* FONTS */
$perpetua: 'Perpetua MT W01', serif;
$perpetua-bold: 'Perpetua MT W01 Bold706778', serif;
$helvetica: 'Helvetica Neue CE 55 Roman', sans-serif;
$helvetica-bold: 'Helvetica Neue CE 75 Bold', sans-serif;
$helvetica-light: 'Helvetica Neue Light', sans-serif;


