.nav {
   @include transition(all, 0.4s);
   height: 174px;
   left: 0px;
   position: sticky;
   top: 0px;
   width: 100%;
   z-index: 3;

   @include for-size(phone) {
      height: 100px;
   }

   &__content {
      align-items: center;
      background-color: $white;
      display: flex;
      justify-content: space-between;
      height: 100%;
      padding: 24px 40px;

      @include for-size(phone) {
         display: block;
         padding: 24px 30px;
      }

      .logo {
         width: 210px;
         z-index: 2;

         @include for-size(phone) {
            width: 170px;
         }
      }

      .links {
         align-items: center;
         display: flex;
         gap: 25px;
         justify-content: end;
         // overflow: hidden;
         width: 80%;
         z-index: 1;

         ul#menu-header-navigation {
            display: flex;
            gap: 15px;
            list-style-type: none;
            margin: 0;
            padding: 0;
            
            @include for-size(tablet) {
               display: none;
            }
            
            li.menu-item {
               border-bottom: 4px solid #fff;
               line-height: 1;

               a {
                  color: $copy;
                  font-size: 16px;
               }
               
               &:hover {
                  border-color: #707070;

                  .sub-menu {
                     height: 130px;
                     overflow: visible;
                     padding-top: 20px;

                     .menu-item {
                        opacity: 1;
                        visibility: visible;
                     }
                     
                  }
               }

               ul.sub-menu {
                  background-color: #fff;
                  height: 0;
                  left: 0;
                  list-style-type: none;
                  overflow: hidden;
                  padding: 0 40px;
                  position: absolute;
                  top: 101px;
                  width: 100%;
                  @include transition(all .3s);
                  z-index: 999;
                  
                  li.menu-item {
                     display: flex;
                     gap: 15px;
                     border-bottom: none;
                     justify-content: space-between;
                     line-height: 1.5;
                     opacity: 0;
                     visibility: hidden;
                     transition: visibility 0.1s, opacity 0.5s linear;

                     a {
                        display: flex;
                        width: 595px;
                     }

                     &:before {
                        content: '';
                        width: 100px;
                     }
                  }
               }

               &#menu-item-191 {
                  ul.sub-menu {
                     li.menu-item {
                        a {
                           width: 437px;
                        }
                     }
                  }
               }
            }
         }

         ul#menu-top-links {
            align-items: center;
            display: flex;
            gap: 20px;
            list-style-type: none;
            margin: 0px;
            overflow: hidden;
            padding: 0px;

            @include for-size(tablet) {
               display: none;
            }

            li {
               display: inline-block;
               font-size: 28px;
               line-height: 1;
               border-bottom: 4px solid #fff;


               a {
                  color: $copy;
                  text-decoration: none;
                  font-size: 16px;
               }

               &.linkedin {
                  a {
                     border-bottom: 0 none;
                     font-size: 0;

                     &:before {
                        content: '\f08c';
                        font-family: "Font Awesome 5 Brands";
                        font-size: 28px;
                        line-height: 1;
                     }
                  }
               }

               &.search {
                  align-items: center;
                  display: flex;
                  gap: 10px;
                  font-size: 16px;

                  a {
                     cursor: pointer;
                  }

                  form.nav-search {
                     width: 0;
                     transition: width 0.5s;

                     &.show {
                        width: 120px;

                        input[type='text'] {
                           background-color: rgba(216,216,216,0.5);
                        }
                     }

                     input[type='text'] {
                        background-color: #fff;
                        border: 0;
                        color: $copy;
                        font-family: $helvetica-light;
                        font-size: 16px;
                        padding: 10px 5px 7px 5px;
                        transition: all 0.5s;

                        @media screen and (max-width:$mobile) {
                           font-size: 14px;
                           padding: 18px;
                        }
                     }

                     ::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: $white;
                        opacity: 1;
                        /* Firefox */
                        text-transform: uppercase;
                        font-family: $helvetica-light;
                     }

                     :-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: $white;
                        text-transform: uppercase;
                        font-family: $helvetica-light;
                     }

                     ::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: $white;
                        text-transform: uppercase;
                        font-family: $helvetica-light;
                     }
                  }
               }
            }
         }
      }
   }
}

/* =================== HAMBURGER ======================= */
#hamburger {
   @include transform(rotate(0deg));
   @include transition(all .5s ease-in-out);
   cursor: pointer;
   display: none;
   height: 30px;
   margin: 0px auto;
   position: fixed;
   right: 30px;
   top: 70px;
   width: 40px;
   z-index: 4;

   &:hover {
      span {
         background-color: $copy;
      }
   }

   @include for-size(tablet) {
      display: block;
      top: 40px;
   }

   span {
      @include transform(rotate(0deg));
      @include transition(.25s ease-in-out);
      background: #000;
      display: block;
      height: 3px;
      opacity: 1;
      position: absolute;
      width: 50%;

      &:nth-child(even) {
         left: 50%;
      }

      &:nth-child(odd) {
         left: 0px;
      }

      &:nth-child(1),
      &:nth-child(2) {
         top: 2px;
      }

      &:nth-child(3),
      &:nth-child(4) {
         top: 12px;
      }

      &:nth-child(5),
      &:nth-child(6) {
         top: 22px;
      }
   }

   &.open {
      span {

         &:nth-child(1),
         &:nth-child(6) {
            @include transform(rotate(45deg));
         }

         &:nth-child(2),
         &:nth-child(5) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
         }

         &:nth-child(1) {
            left: 3px;
            top: 6px;
         }

         &:nth-child(2) {
            left: calc(50% - 5px);
            top: 6px;
         }

         &:nth-child(3) {
            left: -50%;
            opacity: 0;
         }

         &:nth-child(4) {
            left: 100%;
            opacity: 0;
         }

         &:nth-child(5) {
            left: 3px;
            top: 18px;
         }

         &:nth-child(6) {
            left: calc(50% - 5px);
            top: 18px;
         }
      }
   }
}

/* =================== MENU OVERLAY ======================= */
.nav_menu {
   position: fixed;
   top: 0px;
   left: 0px;
   height: 0px;
   width: 100%;
   overflow: hidden;
   @include transition(height .6s ease-out);

   &.open {
      height: 100%;

      .nav_menu__container__menu {
         opacity: 1;
         transition-delay: .2s;
      }
   }

   &__container {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;

      &__overlay {
         background-color: $white;
         height: 100%;
         left: 0px;
         position: absolute;
         top: 0px;
         width: 100%;
      }

      &__menu {
         position: absolute;
         left: 50%;
         top: 50vh;
         @include transform(translate(-50%, -50%));
         @include transition(opacity .3s linear);
         transition-delay: 0s;
         opacity: 0;

         @media screen and (max-width:$mobile) {
            @include transform(none);
            left: 20px;
            top: 70px;
         }

         ul {
            font-weight: bold;
            list-style-type: none;
            margin: 0px;
            padding: 0px;
            font-size: 32px;

            @media screen and (max-width:$mobile) {
               font-size: 35px;
            }

            li {
               margin-top: 10px;

               @media screen and (max-width:$mobile) {
                  margin-top: 14px;
               }
            }

            li ul {
               font-weight: normal;
               margin-bottom: 20px;

               li {
                  margin-top: 10px;
               }

               li a {
                  font-family: $helvetica-light;
                  font-size: 25px;
               }
            }

            a {
               color: $copy;
               text-decoration: none;
               @include transition(color 0.3s linear);
               font-family: $helvetica-light;
               position: relative;

               display: inline-block;

               &:before {
                  content: '';
                  display: block;
                  position: absolute;
                  left: 0px;
                  bottom: 2px;
                  height: 1px;
                  width: 0px;
                  background-color: $white;
                  @include transition(width 0.3s linear);

                  @media screen and (max-width:$mobile) {
                     display: none;
                  }
               }

               &:hover {
                  color: $blue;

                  &:before {
                     width: 100%;
                  }
               }
            }



         }
      }
   }
}