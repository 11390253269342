* {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   box-sizing: border-box;
   outline: none;
   -webkit-overflow-scrolling: touch;
   letter-spacing: 0px;
}

.no-scroll {
   overflow: hidden;
}

body {
   background-color: $body;
   color: $copy;
   font-family: $helvetica-light;
   font-size: 16px;
   line-height: 1.6em;
   margin: 0px;
   padding: 0px 0px 0px;
}

section.wp {
   opacity: 0;
   @include transition(all .3s linear);

   &.visible {
      opacity: 1;
   }
}

p {
   a {
      color: $blue;
   }
}

h1 {
   border-bottom: 2px solid $blue;
   color: $dark-blue-2;
   display: inline-block;
   font-family: $helvetica-bold;
   margin: 0px 0px 10px;
   padding: 0px 0px 10px;
   text-transform: uppercase;
}

h2 {
   font-family: $perpetua;

   &.section_head {
      display: block;
      font-family: 'Perpetua MT W01', serif;
      font-size: 40px;
      font-weight: normal;
      line-height: 1;
      margin: auto auto 50px auto;
      padding-bottom: 8px;
      position: relative;
      text-align: center;
   }
}

h3 {
   color: $blue;
   font-family: $helvetica-bold;
   font-size: 20px;
   // letter-spacing: 2px;
   margin: 2em 0 1em 0;
}

h3.new_subhead {
   border-bottom: 2px solid #41D79E;
   color: #222944;
   display: inline-block;
   font-family: $helvetica-bold;
   font-size: 20px;
   font-weight: 600;
   letter-spacing: 1px;
   margin: 50px 0px 30px 0;
   padding: 0px 0px 10px;
   text-transform: uppercase;
}

h3.name.special {
   color: #222944;
}

section {
   .section-title {
      font-family: $perpetua;
      font-size: 40px;
      line-height: 60px;
      padding: 50px 0;
      text-align: center;

      @include for-size(phone) {
         font-size: 25px;
         padding: 30px 0;
      }
   }
}

blockquote {
   border-bottom: 2px solid $light-gray;
   border-top: 2px solid $light-gray;
   font-size: 23px;
   line-height: 1.6em;
   margin: 1em 0;
}

a {
   text-decoration: none;

   &.read_more {
      @include transition(color 0.3s linear);
      border-bottom: 2px solid $blue;
      color: $dark-blue;
      display: inline-block;
      font-family: $helvetica-bold;
      font-size: 12px;
      padding-bottom: 2px;
      text-transform: uppercase;

      &:hover {
         color: $blue;
      }
   }
}

nav.prev-next {
   border-bottom: 5px solid #D1D1D1;
   margin-bottom: 65px;
   padding: 45px 0;

   .nav-links {
      display: flex;
      justify-content: space-between;

      .nav-previous,
      .nav-next {
         align-items: center;
         color: #5994D0;
         cursor: pointer;
         display: flex;
         font-size: 16px;
         gap: 15px;
         line-height: 18px;
      }
   
      .nav-next {
         .icon {
            transform: rotate(180deg);
         }
      }
   }
}