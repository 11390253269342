.single-person {
   .hero {
      display: none;
      &__copy {
         h2 {
            color: #4B75A0;
         }
      }
   }

   .content {
      margin: 0 auto;
      max-width: 1128px;
      padding-bottom: 150px;
      width: 100%;

      nav {
         border-bottom: 5px solid #D1D1D1;
         padding: 35px 0;
         margin-bottom: 60px;
         
         .nav-links {
            display: flex;
            justify-content: space-between;
            
            .nav-previous,
            .nav-next {
               a{
                  align-items: center;
                  color: #5994D0;
                  cursor: pointer;
                  display: flex;
                  gap: 15px;
                  font-size: 16px;
                  line-height: 18px;
               }
            }
            
            .nav-next {
               a{
                  .icon {
                     @include transform(rotate(180deg));
                  }
               }
            }
         }
      }

      .cols {
         display: flex;
         gap: 40px;

         @include for-size(tablet) {
            flex-direction: column;
         }

         .col {
            img {
               max-width: 350px;

               @include for-size(tablet) {
                  width: 100%;
               }
            }

            .name {
               // font-family: $perpetua-bold;
               font-weight: normal;
               color: #000;
               font-size: 36px;
               margin: 0;
               margin-bottom: 10px;
            }

            .title {
               color: #89AEBE;
               font-size: 18px;
               font-weight: 500;
            }

            .intro {
               color: #7A6E66;
               font-family: $perpetua;
               font-size: 30px;
               line-height: 36px;
               
               p {
                  margin-bottom: 0;
                  margin-top: 20px;
               }
            }

            .bio {
               color: #7A6E66;
               font-family: $helvetica;
               font-size: 18px;
               font-weight: 500;
               line-height: 28px;
            }
         }
      }
   }
}